.contact {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.section-title {
    font-size: 32px;
    font-weight: 700;
}

.contact-container {
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
    
}