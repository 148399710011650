.work-card {
    padding: 8px;
    margin-top: 0px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.work-logo {
    height: 80px;
}

.work-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.work-dates {
    color: black;
}

.work-desc {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    overflow: hidden ;
    text-overflow: ellipsis;
    max-width: 80%;
}

.job-name {
    font-size: 1em; 
    font-weight: bold;
    margin: 0px 0px;
    
}
.company-name {
    font-size: 0.9em; 
    font-style: italic;
    font-weight: bold;
}

@media only screen and (max-width: 720px) {
    .work-card{
        width: 100%;
    }
}
.work-desc ul {
    margin-top: 0;
    margin-bottom: 0;
  }

