.work {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.work-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.work-name {
    margin-top: 40px;
    font-size: 1.5em; 
    font-weight: bold;
    
}