.about {
    padding-bottom: 48px;
}
.about-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.picture {
    height: 420px;
}
.about-info {
    font-size: 24px;
}
.info-name {
    color: blue;
    font-size: 32px;
}

@media only screen and (max-width: 720px) {
    .about-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .picture {
        height: 280px;
    }
    .about-info {
        font-size: 20px;
    }
    .info-name {
        font-size: 26px;
    }
}
.link-button-resume {
    justify-content: center;
    padding: 10px;
    border: 2px solid blue;
    color: black;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
  }

.type {
padding: 8px,0px;
margin-bottom: 40px;
}
  
.link-button span {
margin-left: 10px;
}